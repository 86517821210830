/* Vendor imports */
import React from 'react';
import { graphql } from 'gatsby';
import Header from '../../components/PageLayout/Header';
import Img from 'gatsby-image';
import { Layout, Row, Col } from 'antd';
import { useTranslation } from "react-i18next"

/* App imports */
import SEO from '../../components/seo';
import PostCard from '../../components/PostCard';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import style from './tags.module.less';

const TagPage = ({ data }) => {

  const { i18n, t } = useTranslation("common");

  const path = `tags/${data.sanityCategory.slug.current}`;

  const MenuItems = data.allSanityMenu.nodes;

  return (
    <Layout className="outerPadding">
      <Layout className="container">

        <SEO
          lang = {i18n.language}
          title = {data.sanityCategory.title.translate}
          description = {data.sanityCategory.description.translate}
          imageUrl = {data.sanityCategory.icon.asset.fluid.src}
          path = {path}
          keywords = {t("keywords_def", { returnObjects: true }).push(data.sanityCategory.title.translate)}
        />

        <Header MenuItems={MenuItems} />

        <SidebarWrapper>
          <div className={`marginTopTitle ${style.tagsList}`}>

            <h1>
              # { data.sanityCategory.title.translate }
            </h1>

            <div className={style.bannerImgContainer}>
            <Img className={style.bannerImg} fluid={data.sanityCategory.icon.asset.fluid} title={data.sanityCategory.title.translate} alt={data.sanityCategory.title.translate} />
            </div>
            <h4 className="textCenter">
              {data.sanityCategory.description.translate}
            </h4>
          </div>
          <Row gutter={[20, 20]}>
            { 
              data.allSanityPost.edges.map((val, key) => (
                <Col key={key} xs={24} sm={24} md={12} lg={8}>
                  <PostCard data={val} />
                </Col>
              )) 
            }
          </Row>
        </SidebarWrapper>
      </Layout>
    </Layout>
  );
};


export const query = graphql`
  query Tag ( $language: String, $tagId: String ) {
    allSanityMenu(filter: {disabled: {in: true}}, sort: {fields: indx})  {
      nodes {
        title {
          translate(language: $language)
        }
        path
        externUrl
      }
    }

    sanityCategory(id: { eq: $tagId })  {
      title {
        translate(language: $language)
      }
      description {
        translate(language: $language)
      }
      icon{
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      },
      color {
        hex
      },
      slug {
        current
      }
    }

    allSanityPost(filter: {categories: {elemMatch: {id: {eq: $tagId}}}}) {
      edges {
        node {
          title {
            translate(language: $language)
          }
          date_create(formatString: "Do MMMM, YYYY", locale: $language)
          mainImage{
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          },
          slug {
            current
          },
          body {
            translate(language: $language)
          }
          categories {
            title {
              translate(language: $language)
            }
          }
        }
      }
    }
  }
`

export default TagPage;
